import cn from 'classnames';
import Link from 'next/link';
import React from 'react';
import Logo from '../../public/logo.svg';
import styles from './Footer.module.scss';
import FacebookLogo from '../../public/Facebook.svg';
import InstagramLogo from '../../public/Instagram.svg';
import LinkedInLogo from '../../public/LinkedIn.svg';
import logoStyles from '../../components/assets/Logo.module.scss';
import { VintageHeader } from '../../components';
import { useRouter } from 'next/router';

export const Footer = ({ userStore, setLoginDialogState, ...props }) => {
  const router = useRouter();
  return (
    <div className={'flex flex-col place-content-stretch relative'}>
      <VintageHeader
        className={styles.vintageBorder}
        showHole={router.asPath === '/club' ? false : true}
      />
      <footer
        className={cn(styles.footer, 'bg-brown-background font-libre px-4')}
        {...props}
      >
        <div
          className={cn(
            styles.footerContent,
            'container flex flex-col lg:flex-row justify-between'
          )}
        >
          <div className="flex flex-col justify-between">
            <div>
              <a href="/" aria-label="Go to main BusinessClass.com page">
                <Logo className={logoStyles.logo} />
              </a>
            </div>
            <div className={styles.caption}>
              BusinessClass.com searches hundreds of travel sites at once to
              help you find the best premium travel offers for flights and
              hotels.
            </div>
          </div>
          <div className={'lg:flex lg:w-1/3'}>
            <div className="flex flex-col justify-between lg:w-1/2">
              <Link href="/flights/business-class" prefetch={false}>
                <a className={styles.link} aria-label='Business Class Flights'>Business Class Flights</a>
              </Link>
              <Link href="/about" prefetch={false}>
                <a className={styles.link} aria-label='About us'>About us</a>
              </Link>
              <Link href="/about/#contact" prefetch={false}>
                <a className={styles.link} aria-label='Contact'>Contact</a>
              </Link>
              {userStore && !userStore.userid && (
                <a
                  className={styles.link}
                  onClick={(event) => {
                    event.preventDefault();
                    setLoginDialogState({ state: 'signUp', opened: true, sourcePage: 'Footer Newsletter' });
                  }}
                  aria-label='Signup to Newsletter'
                >
                  Newsletter
                </a>
              )}

              <a className={styles.link} href="/authors" aria-label='Authors'>
                Authors
              </a>
            </div>
            <div className="flex flex-col lg:w-1/2">
              <Link href="/view/privacy_policy" prefetch={false}>
                <a className={styles.link} aria-label='Privacy Policy'> Privacy Policy</a>
              </Link>
              <Link href="/view/terms_conditions" prefetch={false}>
                <a className={styles.link} aria-label='Terms & conditions'>Terms & conditions</a>
              </Link>
              <Link href="/view/cookies_policy" prefetch={false}>
                <a className={styles.link} aria-label='Cookies Policy'>Cookies Policy</a>
              </Link>
              <a className={styles.link} href="/archive" aria-label='Archive'>
                Archive
              </a>
            </div>
          </div>

          <div className="flex flex-col items-start justify-between sm:mt-4 lg:mt-0">
            <div className={'flex flex-col'}>
              <div className={cn(styles.captionConnect, 'w-full mb-2')}>
                Connect with us:
              </div>
              <div className={'flex'}>
                <a
                  href="https://www.facebook.com/Businessclass/"
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                  aria-label='Open Facebook group page'
                >
                  <FacebookLogo className={styles.snLogo} />
                </a>
                <a
                  href="https://instagram.com/businessclasscom"
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                  aria-label='Open Instagram page'
                  className="ml-4"
                >
                  <InstagramLogo className={styles.snLogo} />
                </a>
                <a
                  href="https://www.linkedin.com/company/businessclass.com"
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                  aria-label='Open LinkedIn page'
                  className="ml-4"
                >
                  <LinkedInLogo className={styles.snLogo} />
                </a>
              </div>
            </div>
            <div className={cn(styles.captionConnect, 'text-start pt-5')}>
              © 2024 BusinessClass.com ® <br />
              All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
