import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

const frontPageDelay = 1000 * 30;
const commonDelay = 1000 * 8;

const showSignUp = () => {
  const [dialogCount, setDialogCount] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  const router = useRouter();

  useEffect(() => {
    // Get the dialog count from session storage
    const sessionDialogCount = sessionStorage.getItem('bc_signUpdialogCount');
    if (sessionDialogCount) {
      setDialogCount(parseInt(sessionDialogCount, 10));
    }
  }, []);

  useEffect(() => {
    // Update session storage whenever dialogCount changes
    sessionStorage.setItem('bc_signUpdialogCount', dialogCount);
    // Show dialog only if count is less than 2
    if (dialogCount < 2) {
      let timeout;
      if (router.pathname === '/') {
        timeout = setTimeout(() => {
          setShowDialog(true);
        }, frontPageDelay);
      } else {
        timeout = setTimeout(() => {
          setShowDialog(true);
        }, commonDelay);
      }
      return () => clearTimeout(timeout);
    } else {
      setShowDialog(false);
    }
  }, [dialogCount, router.pathname]);

  const incrementDialogCount = () => {
    setDialogCount((prevCount) => prevCount + 1);
  };

  return { showDialog, incrementDialogCount };
};

export default showSignUp;