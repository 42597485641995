import cn from 'classnames';
import { object, string, ref } from 'yup';
import { ErrorMessage, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { httpPost } from '../../../../utils/httpClient';
import styles from '../../Authentication.module.scss';
import { AuthInput } from '../AuthInput/AuthInput';

const validationSchema = object().shape({
  password: string()
    .required('Password is required'),
  passwordRepeat: string()
    .oneOf([ref('password'), null], 'Passwords must match')
    .required('Repeat password is required'),
});

export const ResetPassword = ({ props, token, authCompleted }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const resetPassword = (value) => {
    setLoading(true);
    httpPost('/api/user/resetEmail', { password: value.password, token }).then(
      (resolve) => {
        if (resolve.ok) {
          setLoading(false);
          authCompleted();
        } else {
          setLoading(false);
          setError('Something went wrong, please try again later');
        }
      }
    );
  };
  return (
    <Formik
      initialValues={{
        password: '',
        passwordRepeat: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        resetPassword(values);
      }}
      className="flex flex-col items-center"
    >
      {() => (
        <Form>
          <div {...props} className={cn(styles.passwordReset, "flex flex-col items-center")}>
            <span
              className={cn(styles.header, 'text-center my-4 font-fragment')}
            >
              Please enter your new password
            </span>
            <div className="max-w-sm flex flex-col">
              <AuthInput name="password" type="password" label="Password" />
              <ErrorMessage name="password" component="div" className={cn(styles.inputErrorMessage, 'text-left')} />
              <AuthInput
                name="passwordRepeat"
                type="password"
                label="Repeat password"
              />
              <ErrorMessage name="passwordRepeat" component="div" className={cn(styles.inputErrorMessage, 'text-left')} />
              {loading && <div className={styles.loader} />}
              {error && <div className={styles.error}>{error}</div>}
              <button
                type="submit"
                className={cn(
                  styles.loginButton,
                  'text-white font-serif mx-auto my-3'
                )}
              >
                Continue
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
