import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { Button, createTheme, Dialog, ThemeProvider } from '@mui/material';
import { useWindowWidth } from '@react-hook/window-size';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { InView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { httpGet, httpPost } from '../utils/httpClient';
import { setUser } from '../utils/store/features/userSlice';
import { Footer } from './Footer/Footer';
import { Header } from './Header/Header';
import styles from './Layout.module.scss';
import cn from 'classnames';
import { ResetPassword } from '../components/Authentication/components/ResetPassword/ResetPassword';
import { SignUp } from '../components/Authentication/components/SignUp/SignUp';
import { PasswordRecovery } from '../components/Authentication/components/PasswordRecovery/PasswordRecovery';
import { SetPassword } from '../components/Authentication/components/SetPassword/SetPassword';
import { Login } from '../components/Authentication/components/Login/Login';
// import Logo from '../public/logo.svg';
// import { DEALS_COUNTRIES } from '../config';
import { Form, Formik } from 'formik';
import { FlightSearchAutocomplete } from '../components/FlightSearch/FlightSearchAutocomplete/FlightSearchAutocomplete';
import showSignUp from './hooks/showSignUp';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00454A',
    },
    secondary: {
      main: '#202020',
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", sans-serif',
    color: '#202020',
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          borderRadius: '10px',
        },
      },
    }
  }
});
const Authentication = dynamic(() =>
  import('../components/Authentication/Authentication')
);
const getUserInfo = async () => {
  try {
    const res = await httpGet(`/api/user/session`);
    return await res.json();
  } catch (e) {
    console.log(e);
  }
  return null;
};
const Layout = ({ children, transparentHeader, transparentHeaderMobile }) => {
  useEffect(() => {
    if (children.props.transparentHeader !== undefined) {
      setTransparentHeader(children.props.transparentHeader);
    }
  }, [children]);

  const [sessionState, setSessionState] = useState(null);
  const [userId, setUserId] = useState(null);
  const [cookieAccepted, setCookieAccepted] = useState({
    accepted: false,
    opened: false,
  });
  const [dealsDialogShown, setDealsDialogShown] = useState(false);
  const [transparentHeaderState, setTransparentHeader] =
    useState(transparentHeader);
  const userStore = useSelector((state) => state.user.value);
  const dispatch = useDispatch();
  const firstAttempt = 10 * 1000;
  const secondAttempt = 80 * 1000;
  const [loginDialogState, setLoginDialogState] = useState({
    state: 'signUp',
    opened: false,
    customHeading: null,
    changeState: null,
    sourcePage: 'Common',
    redirectOffer: null,
  });
  const [dealsLoginDialogState, setDealsLoginDialogState] = useState({
    state: 'login',
    opened: false,
    sourcePage: 'Common'
  });
  const [emailVerificationDialog, setEmailVerificationDialog] = useState({
    state: 'verified',
    opened: false,
  });
  const [passwordResetDialog, setPasswordResetDialog] = useState({
    token: '',
    opened: false,
  });
  const [airportDialog, setAirportDialog] = useState({
    opened: false
  });
  const [showSignUpDialog, setShowSignUpDialog] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const width = useWindowWidth();
  const router = useRouter();
  const authCompleted = () => {
    setLoginDialogState({ ...loginDialogState, opened: false });
    setShowSignUpDialog(false);
    session();
  };

  const dealsAuthCompleted = () => {
    authCompleted();
    setDealsDialogShown(true);
    setDealsLoginDialogState({ ...dealsLoginDialogState, opened: false });
    document.cookie = `ibe.dealsDialogShown=true;max-age=${
      3600 * 24 * 182.5
    };domain=.businessclass.com`;
    localStorage.setItem('dealsDialogShown', JSON.stringify(true));
    window.dispatchEvent(new Event('dealsStorage'));
  }
  const resetCompleted = () => {
    setPasswordResetDialog({ ...passwordResetDialog, opened: false });
    session();
  };
  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        setLoginDialogState,
        setDealsLoginDialogState,
        authCompleted,
        setCookieAccepted,
        setDealsDialogShown,
      });
    }
    return child;
  });

  const { showDialog, incrementDialogCount } = showSignUp();

  useEffect(() => {
    if (showDialog && !userStore?.userid && loginDialogState.state === 'setAirport') {
      setLoginDialogState({ ...loginDialogState, opened: true, state: 'signUp'});
    }

    if (userStore?.userid && loginDialogState?.state === 'setAirport') {
      setLoginDialogState({ ...loginDialogState, opened: true, state: 'setAirport' });
    }

  }, [showDialog, userStore]);

  useEffect(() => {
    setWindowWidth(width);
    if (width <= 1024) {
      setTransparentHeader(transparentHeaderMobile);
    } else {
      setTransparentHeader(transparentHeader);
    }
  }, [width]);
  useEffect(() => {
    if (!getCookie('ibe.acceptedCookie') && process.env.prod === 'true') {
      localStorage.removeItem('bcCookieAccepted');
    }
    let cookieState = JSON.parse(localStorage.getItem('bcCookieAccepted'));
    setCookieAccepted({
      accepted: cookieState,
      opened: !cookieState,
    });
    if (cookieState) {
      session();
    } else {
      window.addEventListener('storage', (e) => {
        cookieState = JSON.parse(localStorage.getItem('bcCookieAccepted'));
        if (cookieState) {
          session();
        }
      });
    }

    if (!getCookie('ibe.dealsDialogShown') && process.env.prod === 'true') {
      localStorage.removeItem('dealsDialogShown');
    }
    let dealsDialogCookieState = JSON.parse(localStorage.getItem('dealsDialogShown'));
    setDealsDialogShown(dealsDialogCookieState);

    if (!dealsDialogCookieState) {
      window.addEventListener('dealsStorage', (e) => {
        dealsDialogCookieState = JSON.parse(localStorage.getItem('dealsDialogShown'));
        setDealsDialogShown(dealsDialogCookieState);
      });
    }
  }, []);

  useEffect(() => {
    if (+router.query.login) {
      setLoginDialogState({ state: 'login', opened: true, sourcePage: 'Login url link' });
    }
    if (+router.query.signup) {
      setLoginDialogState({ state: 'signUp', opened: true, sourcePage: 'Signup url link' });
    }
    if (router.query['reset-token']) {
      setPasswordResetDialog({
        token: router.query['reset-token'],
        opened: true,
      });
    }
    if (+router.query.verified) {
      setEmailVerificationDialog({ state: 'verified', opened: true });
      const tagManagerArgs = {
        dataLayer: {
          event: 'accountVerified',
          userCountry: userStore?.country.toUpperCase(),
          pageType: router?.pathname,
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
    if (+router.query.verifyfail) {
      setEmailVerificationDialog({ state: 'verifyfail', opened: true });
      const tagManagerArgs = {
        dataLayer: {
          event: 'accountVerificationFail',
          userCountry: userStore?.country.toUpperCase(),
          pageType: router?.pathname,
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  }, [router.isReady, router.query])

  useEffect(() => {
    if (userStore?.isSNAuth) {
      setAirportDialog({opened: true});
    }
  }, [userStore?.isSNAuth])

  function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  const session = () => {
    getUserInfo()
      .then((userInfo) => {
        const user = !userInfo?.country
          ? {
              userid: null,
              country: 'us',
              is_admin: null,
              currency: 'USD',
              username: null,
              fullname: null,
              membershipNumber: null,
            }
          : userInfo;
        if (user.country === 'uk') {
          user.country = 'gb';
        }
        dispatch(setUser(user));
        setSessionState(user);
        if (
          user &&
          !user.userid &&
          +sessionStorage.getItem('bc_popup_count') !== 1 &&
          !window.location.href.includes('flightdeals')
        ) {
          setShowSignUpDialog(true);
        }

        // Opens clicked offer page after authentication in a new tab
        if (user?.userid && loginDialogState.redirectOffer) {
          const isETGOffer = loginDialogState.redirectOffer.source === 'Etraveli';
          const redirectUrl = isETGOffer ? `${loginDialogState.redirectOffer.url}$${user?.membershipNumber}` : loginDialogState.redirectOffer.url;
          window.open(redirectUrl, '_blank');
        }

        // if (
        //   user &&
        //   !user.userid &&
        //   +sessionStorage.getItem('bc_signUp_count') !== 2
        // ) {
        //   setTimeout(() => {
        //     setLoginDialogState({ ...loginDialogState, opened: true, state: 'signUp' });
        //     sessionStorage.setItem('bc_signUp_count', 1);
        //   }, firstAttempt);
        //   setTimeout(() => {
        //     setLoginDialogState({ ...loginDialogState, opened: true, state: 'signUp' });
        //     sessionStorage.setItem('bc_signUp_count', 2);
        //   }, secondAttempt);
        // }
      })
      .catch((err) => {
        console.log(err);
        if (sessionState) {
          dispatch(setUser({}));
          setSessionState(null);
        }
      });
  };

  const topContentSignup = (
    <div className='flex'>
      <div className={styles.dealsDialog}>
        {/* <h2 className={cn(styles.heading, 'font-libre')}>Claim FREE membership of The Club</h2> */}
        {/* <div className='font-libre text-white font-sm mb-6 text-center'>Powered by <Logo className='inline-block h-6 pl-1'/></div> */}


          <h2 className={cn(styles.heading, 'font-libre')}>Don’t miss out on <span className={styles.higlighted}>exclusive</span> FlightDeals!</h2>

          <div>
            <h3 className='my-3 font-libre text-base lg:text-xl text-left font-normal'>
              The best deals go fast. Join <a className='underline' href='/club'>The Club</a> for free and be the first to receive them – direct to your inbox. <br /><br />
              Enter your email below to make sure you never miss out again!
            </h3>
          </div>
          {/* {DEALS_COUNTRIES.includes(userStore?.country) && (
            <div>
              <p className='mb-2 font-libre'>
                Welcome to FlightDeals - our new tool that makes it easy to get the best price for your chosen destination.
              </p>

              <p className='mb-2 font-libre'>
                With every search, FlightDeals gets smarter - the more you search, the more likely it is to find your ideal offer.
              </p>
            </div>
          )}

          {!DEALS_COUNTRIES.includes(userStore?.country) && (
            <p className='my-2 font-libre italic bold p-2 border border-white'>
              We're sorry, but we don't offer FlightDeals in your country and only support users in Australia, Norway, England, Scotland and Wales.
              <br /><br />
              We are working to bring FlightDeals to your region as soon as possible.
              <br />
              You are welcome to sign-up now as a free member of The Club, and when FlightDeals is available to you, we will let you know in newsletters and your inbox.
              <br /><br />
              Thank you & safe travels,
              <br />
              The BusinessClass.com Team
            </p>
          )} */}

          {/* {dealsLoginDialogState.state !== 'welcome' && ( */}
            <p className='font-libre'>
              {/* {!DEALS_COUNTRIES.includes(userStore?.country) && (
                <p className='text-center'><b>Sign up for free</b></p>
              )}

              {DEALS_COUNTRIES.includes(userStore?.country) && (
                <span> Log in or <b>sign up for free</b> to access FlightDeals</span>
              )} <br /> <br /> */}

              {/* <div className='grid lg:grid-cols-2 gap-x-4'>
                <ul className='lg:text-lg list-disc'>
                  <li>Fabulous member-only fares</li>
                  <li>Add your destinations into MapMyTravel</li>
                </ul>

                <ul className='lg:text-lg list-disc lg:ml-4'>
                  <li>Newsletters and deal alerts</li>
                  <li>Access to exclusive reviews & guides</li>
                </ul>
              </div> */}
              
            </p>
          {/* )} */}
      </div>
    </div>
  );

  return (
    <>
      <Head>
        <title>BusinessClass.com</title>
        {process.env.prod === 'false' && (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/assets/bc-logo-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/assets/bc-logo-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/assets/bc-logo-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/assets/bc-logo-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/assets/bc-logo-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/assets/bc-logo-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/assets/bc-logo-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/assets/bc-logo-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/bc-logo-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/assets/bc-logo-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/bc-logo-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/assets/bc-logo-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/bc-logo-16x16.png"
        />
        <link rel="icon" type="/image/x-icon" href="/assets/favicon.ico" />
        <link rel="shortcut" href="/assets/favicon.ico" />
        <meta
          name="google-site-verification"
          content="kcIPUazHduiHwHhy9ojS1uAgSiXPfgSgiMN4mvapA24"
        />
        <meta name="verification" content="d78abf430d4b4c2601deb16b9ce49d52" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta
          name="description"
          content="BusinessClass.com searches hundreds of travel sites at once to help you find the best premium travel offers for flights and hotels."
        />
      </Head>
      <div className={cn(styles.layout, 'bg-review-bg', 'h-full', 'relative')}>
        <Header
          handleSession={getUserInfo}
          transparentHeader={transparentHeaderState}
        />
        {childrenWithProps}
        {showSignUpDialog && (
          <div className={styles.signUpDialogContainer}>
            <div className={styles.registerOverlay}>
              <div className="font-libre">
                <IconButton
                  color="inherit"
                  onClick={() => {
                    setShowSignUpDialog(false);
                    sessionStorage.setItem('bc_popup_count', 1);
                  }}
                  aria-label="close"
                  className={styles.closeSignUpDialog}
                >
                  <CloseIcon />
                </IconButton>
                <button
                  type="button"
                  onClick={(event) => {
                    event.preventDefault();
                    setLoginDialogState({ state: 'signUp', opened: true, sourcePage: 'Teaser click' });
                  }}
                  className={cn(styles.signUpButton, 'font-fragment')}
                >
                  Claim FREE membership of The Club
                </button>

              </div>
            </div>
          </div>
        )}
        <Dialog
          open={loginDialogState.opened}
          onBackdropClick={() => {
            incrementDialogCount();
            setLoginDialogState({ ...loginDialogState, opened: false });
          }}
          PaperProps={{
            style: { borderRadius: 0 },
          }}
         
          fullScreen={windowWidth <= 520}
          fullWidth={false}
          maxWidth={'md'}
        >
          <div className={styles.loginDialog}>
            <IconButton
              color="inherit"
              onClick={() => {
                incrementDialogCount();
                setLoginDialogState({ ...loginDialogState, opened: false });
              }}
              aria-label="close"
              className={styles.closeDialog}
            >
              <CloseIcon />
            </IconButton>
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref} className="w-full mx-auto">
                  {inView && (
                    <Authentication
                      authCompleted={authCompleted}
                      state={loginDialogState.state}
                      customHeading={loginDialogState.customHeading}
                      changeState={loginDialogState.changeState}
                      sourcePage={loginDialogState.sourcePage}
                    />
                  )}
                </div>
              )}
            </InView>
          </div>
        </Dialog>
        <Dialog
          open={((dealsLoginDialogState.opened && dealsLoginDialogState.state !== 'welcome')) && !userStore?.userid && !loginDialogState?.opened} 
          onBackdropClick={() => {
            // if(dealsLoginDialogState.state === 'welcome') {
            //   setDealsLoginDialogState({ ...dealsLoginDialogState, opened: false });
            // }
            setDealsLoginDialogState({ ...dealsLoginDialogState, opened: false });
          }}
          PaperProps={{
            style: { borderRadius: 0 },
          }}
          // fullScreen={windowWidth <= 420}
          fullWidth={true}
          maxWidth={'lg'}
        >
          {/* {dealsLoginDialogState.state === 'welcome' && ( */}
            <IconButton
             color="inherit"
             onClick={() => {
               setDealsLoginDialogState({ ...dealsLoginDialogState, opened: false });
               dealsAuthCompleted();
             }}
             aria-label="close"
             className={styles.closeDialog}
           >
             <CloseIcon />
           </IconButton>
          {/* )} */}
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className={cn(styles.dealsDialogContainer, 'w-full mx-auto')}>
                {inView && (
                  <>
                    <div className='flex'>
                      <div className='lg:w-1/2 ml-auto p-3'>
                        {dealsLoginDialogState.state === 'welcome' && !dealsDialogShown && (
                          <div className='min-h-100'>
                            {topContentSignup}
                            <div className='flex mt-8 mb-8'>
                              <button
                                className={cn(
                                  styles.proceedButton,
                                  'text-white font-serif mx-auto'
                                )}
                                onClick={() => {
                                  setDealsLoginDialogState({ ...dealsLoginDialogState, opened: false });
                                  dealsAuthCompleted();
                                }}
                              >
                                Proceed
                              </button>
                            </div>
                          </div>
                        )}
                        {dealsLoginDialogState.state === 'login' && (
                          <Login
                            authCompleted={dealsAuthCompleted}
                            changeState={(state) => {
                              setDealsLoginDialogState({opened: true, state, sourcePage: 'FlightDeals popup'});
                            }}
                            darkMode={true}
                            sourcePage={'FlightDeals popup'}
                          />
                        )}
                        {dealsLoginDialogState.state === 'signUp' && (
                          <SignUp
                            authCompleted={dealsAuthCompleted}
                            customTopContent={topContentSignup}
                            setUserId={setUserId}
                            changeState={(state) => {
                              setDealsLoginDialogState({opened: true, state, sourcePage: 'FlightDeals popup'});
                            }}
                            darkMode={true}
                            sourcePage={'FlightDeals popup'}
                          ></SignUp>
                        )}

                        {dealsLoginDialogState.state === 'passwordReset' && (
                          <PasswordRecovery 
                            darkMode={true}
                            changeState={(state) => {
                            setDealsLoginDialogState({opened: true, state});
                          }} />
                        )}
                        {dealsLoginDialogState.state === 'setPassword' && (
                          <SetPassword
                            changeState={(state) => {
                              setDealsLoginDialogState({opened: true, state, sourcePage: 'FlightDeals popup'});
                            }}
                            userId={userId}
                            authCompleted={dealsAuthCompleted}
                            darkMode={true}
                            sourcePage={'FlightDeals popup'}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </InView>
        </Dialog>
        <Dialog
          open={cookieAccepted.opened}
          disableEscapeKeyDown
          PaperProps={{
            style: { borderRadius: 0 },
          }}
          maxWidth={'sm'}
          aria-label='Your privacy on BusinessClass.com'
        >
          <div className={cn(styles.cookieDialog, 'font-libre')}>
            <ThemeProvider theme={theme}>
              <div
                className={cn(styles.cookieDialogHeading, 'font-garamond my-8')}
              >
                Your privacy on BusinessClass.com
              </div>
              <div className={'mb-8'}>
                We use technical, analytical and marketing cookies to
                personalise your experience. To find out more, read our{' '}
                <a href="/view/privacy_policy">Privacy Policy</a>,{' '}
                <a href="/view/terms_conditions">Terms & Conditions</a> and{' '}
                <a href="/view/cookies_policy">Cookie Policy</a>.
              </div>
              <div className={'flex flex-col w-1/2 justify-center'}>
                <Button
                  color="secondary"
                  variant="contained"
                  className="my-2"
                  type="button"
                  onClick={() => {
                    setCookieAccepted({ accepted: true, opened: false });
                    document.cookie = `ibe.acceptedCookie=true;max-age=${
                      3600 * 24 * 182.5
                    };domain=.businessclass.com`;
                    localStorage.setItem('bcCookieAccepted', true);
                    window.dispatchEvent(new Event('storage'));
                  }}
                >
                  Accept
                </Button>
              </div>
            </ThemeProvider>
          </div>
        </Dialog>
        <Dialog
          open={emailVerificationDialog.opened}
          disableEscapeKeyDown
          PaperProps={{
            style: { borderRadius: 0 },
          }}
          maxWidth={'sm'}
        >
          <div className={cn(styles.cookieDialog, 'font-libre')}>
            <ThemeProvider theme={theme}>
              <IconButton
                color="inherit"
                onClick={() =>
                  setEmailVerificationDialog({
                    ...emailVerificationDialog,
                    opened: false,
                  })
                }
                aria-label="close"
                className={styles.closeDialog}
              >
                <CloseIcon />
              </IconButton>
              <div
                className={cn(styles.cookieDialogHeading, 'font-garamond my-8')}
              >
                {emailVerificationDialog.state === 'verified'
                  ? 'Email Verified!'
                  : 'Email verification failed!'}
              </div>
              <div className={'mb-8'}>
                {emailVerificationDialog.state === 'verified' ? (
                  <>
                    Your email address has been verified. <br />
                    Welcome to BusinessClass.com
                  </>
                ) : (
                  <>Verification link is expired. Please try once more.</>
                )}
              </div>
            </ThemeProvider>
          </div>
        </Dialog>{' '}
        <Dialog
          open={passwordResetDialog.opened}
          disableEscapeKeyDown
          PaperProps={{
            style: { borderRadius: 0 },
          }}
          maxWidth={'sm'}
        >
          <div className={cn(styles.cookieDialog, 'font-libre')}>
            <ThemeProvider theme={theme}>
              <IconButton
                color="inherit"
                onClick={() =>
                  setPasswordResetDialog({
                    ...passwordResetDialog,
                    opened: false,
                  })
                }
                aria-label="close"
                className={styles.closeDialog}
              >
                <CloseIcon />
              </IconButton>
              <div>
                <ResetPassword
                  token={passwordResetDialog.token}
                  authCompleted={resetCompleted}
                />
              </div>
            </ThemeProvider>
          </div>
        </Dialog>

        <Dialog 
          open={airportDialog.opened}
          maxWidth={'sm'}
          disableEscapeKeyDown>
          <ThemeProvider theme={theme}>
            <div className={cn( 'p-5')}>
              <div className={styles.dealsDialog}>
                <h2 className={cn('font-libre')}>
                  Please select your preferred airport
                </h2>
                <p className="text-sm font-libre text-center mb-4 text-black">We will use it as an origin point to show you personalized flight deals. <br /> You can update this later in your profile settings.</p>
                <Formik
                  initialValues={{
                    userAirport: null
                  }}
                  onSubmit={(value) => {
                    const userAirport = { 
                      code: value.userAirport?.Iata,
                      name: value.userAirport?.ShortName,
                      isCity: !value.userAirport?.ParentAirport
                    };
                    httpPost('/api/user/airport', userAirport)
                      .then((resolved) => {
                        if (resolved.ok) {
                          setAirportDialog({opened: false});
                          if (!router.pathname.includes('flightsearch')) {
                            window.location.href = '/profile?accountCreated=true';
                          } else {
                            window.open('/profile?accountCreated=true', '_blank');
                          }
                        }
                        if (!resolved.ok) {
                          throw resolved.json();
                        }
                      })
                      .catch(async (error) => {
                        console.log((await error).msg || (await error).reason?.[0].msg);
                      });
                  }}
                  className="flex flex-col items-end"
                >
                  {({ setFieldValue, errors, touched, values }) => (
                    <Form className="flex flex-col">
                      <FlightSearchAutocomplete
                        setFieldValue={setFieldValue}
                        name="userAirport"
                        label="Preferred departure Airport"
                        showLabel={false}
                        icon={undefined}
                        error={!!(touched.userAirport && errors.userAirport)}
                        patch={{ value: values.userAirport }}
                      />

                    <div className='flex'>
                      <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        className={cn(
                          styles.proceedButton,
                          'text-white font-serif mt-4 mx-auto'
                        )}
                      >
                        Continue
                      </Button>
                    </div>
                    
                    </Form>
                  )}
                </Formik>
                </div>
            </div>
          </ThemeProvider>
        </Dialog>

        <Footer
          userStore={userStore}
          setLoginDialogState={setLoginDialogState}
        />
      </div>
    </>
  );
};
export const withLayout = (
  Component,
  transparentHeader,
  transparentHeaderMobile = false
) => {
  return function withLayoutComponent(props) {
    return (
      <Layout
        transparentHeader={transparentHeader}
        transparentHeaderMobile={transparentHeaderMobile}
      >
        <Component {...props} />
      </Layout>
    );
  };
};
